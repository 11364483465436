import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Image from "../images/mapsBarbazza.jpg"

const useStyles = makeStyles(theme => ({
  root: {},
  banner: {
    overflow: "visible",
    marginTop: "-140px",
    paddingTop: "100px",
    backgroundColor: "#0a2540",
    transform: "skew(0deg, -6deg)",
  },
  bannerContent: {
    paddingTop: "150px",
    transform: "skew(0deg, 6deg)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "80px",
    },
  },
  chiSiamo: {
    paddingTop: "65px",
    paddingBottom: "45px",
  },
  tabsMenu: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}))

const ChiSiamo = () => {
  const classes = useStyles()
  return (
    <Layout title="Chi siamo">
      <div className={classes.root}>
        <section className={classes.banner}>
          <Container className={classes.bannerContent}>
            <Grid container>
              <Grid item xs={10} style={{ paddingBottom: 20 }}>
                <h1
                  style={{
                    color: "#fe5f55",
                    fontFamily: "Montserrat",
                    fontDisplay: "swap",
                  }}
                >
                  Chi siamo
                </h1>
                <p
                  style={{
                    color: "#fff",
                    fontFamily: "Montserrat",
                    fontDisplay: "swap",
                  }}
                >
                  Jesolo San Dona Basket: La prima associazione di pallacanestro
                  della zona di San Dona di Piave
                </p>
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
            </Grid>
          </Container>
        </section>
        <section className={classes.chiSiamo}>
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://goo.gl/maps/KEbrLBVAgSg2TLMv6"
                >
                  <img
                    src={Image}
                    alt="mappa palazzetto dello sport Guido Barbazza San Dona di Piave"
                  />
                </a>
              </Grid>
            </Grid>
          </Container>
        </section>
      </div>
    </Layout>
  )
}

export default ChiSiamo
